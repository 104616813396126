<template>
  <section id="project-analysis-text-main">
    <Search
      :textAnalysisObject="textAnalysisObject"
      :sortedResponsesProp="sortedResponsesProp"
      :responsesCount="responsesCount"
      :sortedResponsesLength="sortedResponsesLength"
      :propReadOnly="propReadOnly"
      :hasFetchedLemmas="hasFetchedLemmas"
      :hasFetchedSentences="hasFetchedSentences"
      :includeSmartCoverage="includeSmartCoverage"
      :sortedThemes="sortedThemes"
      :unsortedResponses="unsortedResponses"
      @runSortAndSelect="$emit('runSortAndSelect')"
      @updateTextObject="$emit('updateTextObject', $event)"
      @findSimilar="$emit('findSimilar')"
      @updateFilterParam="$emit('updateFilterParam', $event)"
      @removeThisFilter="$emit('removeThisFilter', $event)"
      @emitSortAndSelect="$emit('emitSortAndSelect')"
      :searchingForWordAssociations="searchingForWordAssociations"
    />
    <div style="display: flex; flex-direction: column">
      <div style="position: relative"></div>
      <h2 style="margin: 2rem 2rem 1rem 2rem">
        <div style="display: flex; flex-direction: row">
          <VisibleText>Mode: </VisibleText>
        </div>
      </h2>

      <div style="display: flex; flex-direction: row">
        <SvgIconDecorative
          icon="phraseSentiment"
          class="editor-question__pending-icon"
          style="color: black; margin-left: 5%"
        />
        <div>
          <VisibleText>Sentiment analysis</VisibleText>
        </div>
        <div
          @click="setShowSentimentDetails(true)"
          style="
            display: flex;
            cursor: pointer;
            border: 1px solid var(--outline-colour);
            border-radius: 3rem;
            height: 1rem;
            width: 1rem;
            margin-left: 1rem;
          "
        >
          <span
            style="padding-left: 0.25rem; font-size: 0.8rem; font-weight: 500"
          >
            ?</span
          >
        </div>
        <div @click="showSentimentTip">
          <SwitchToggle
            style="display: flex; margin-left: -0.5rem"
            ref="switch-toggle"
            class="main__tabs-container-options-curr-view switch--small switch--monochromatic"
            :class="!hasFetchedSentiments ? 'switch--disabled' : ''"
            title="set current view mode"
            :hasFetchedSentiments="hasFetchedSentiments"
            :textAnalysisObject="textAnalysisObject"
            @onToggle="handleToggleUpdate"
          />
        </div>
        <SvgIconDecorative
          icon="tag"
          class="editor-question__pending-icon"
          style="color: black"
        />
        <div>
          <VisibleText>Thematic analysis</VisibleText>
        </div>

        <div
          @click="showThematicAnalysisInfo = true"
          style="
            display: flex;
            cursor: pointer;
            border: 1px solid var(--outline-colour);
            border-radius: 3rem;
            height: 1rem;
            width: 1rem;
            margin-left: 0.8rem;
          "
        >
          <span
            style="padding-left: 0.25rem; font-size: 0.8rem; font-weight: 500"
          >
            ?</span
          >
        </div>
        <div style="position: relative">
          <SentimentsMiniGuide
            @closingGuide="showSentimentsInfo = false"
            :showSentimentsInfo="showSentimentsInfo"
          >
          </SentimentsMiniGuide>
          <ThematicAnalysisMiniGuide
            @closingGuide="showThematicAnalysisInfo = false"
            :showThematicInfo="showThematicAnalysisInfo"
          >
          </ThematicAnalysisMiniGuide>
        </div>

        <div
          style="display: flex; margin-left: 2rem"
          v-if="
            (!hasFetchedSentiments ||
              textAnalysisObject.includeSentiments !== 'include') &&
            revealSentimentTip
          "
        >
          <MessageBlock message-type="warning" title="Sentiment mode disabled">
            You need to generate or activate sentiments to view these charts.
          </MessageBlock>
        </div>
      </div>
    </div>
    <Accordion
      class="main__collapsible-chart"
      title-element-type="h3"
      icon-location="right"
      :expand="false"
      @toggled="shouldRenderPosNegChart($event)"
      style="padding: 0 1rem 0 1rem"
      :iconColor="'var(--base-lighter-ui-colour)'"
    >
      <template #heading>
        <span class="main__collapsible-chart-title">
          <VisibleText v-if="localAciveTab.toLowerCase() == 'sentiment'"
            >Sentiment chart and table</VisibleText
          >
          <VisibleText v-else>Table</VisibleText>
        </span>
      </template>
      <p v-if="barChartNode.content" class="main__collapsible-chart-heading">
        <VisibleText>Showing </VisibleText>
        {{ barChartNode.content.data.length }}
        <VisibleText> out of </VisibleText>
        {{ responsesCountTotal }}
        <VisibleText> entries.</VisibleText>
      </p>
      <PositiveNegativeBarChart
        ref="pos-neg-chart"
        class="main__collapsible-chart-content"
        v-if="barChartNode"
        :value="barChartNode"
        @change="selectionChanged"
      />
    </Accordion>
    <Accordion
      class="main__tabs"
      title-element-type="h3"
      icon-location="right"
      :expand="true"
      style="padding: 0 1rem 1rem 1rem"
      :iconColor="'var(--base-lighter-ui-colour)'"
    >
      <template #heading>
        <span class="main__tabs-title">
          <SvgIconDecorative
            :icon="
              localAciveTab.toLowerCase() == 'sentiment'
                ? 'phraseSentiment'
                : 'tag'
            "
            class="editor-question__pending-icon"
            style="color: black"
          />

          <VisibleText>Text entries</VisibleText>
          {{
            `(${
              responsesCount - responsesDroppedCount - responsesBannedCount
            }/${responsesCountTotal})`
          }}
        </span>
        <span
          style="margin-left: 1rem"
          v-if="
            (responsesCount - responsesDroppedCount - responsesBannedCount) /
              responsesCountTotal !=
            1
          "
        >
          {{
            roundToTwo(
              ((responsesCount - responsesDroppedCount - responsesBannedCount) /
                responsesCountTotal) *
                100
            )
          }}
          %
        </span>
        <span
          style="margin-left: 1rem; color: var(--teritary-ui-colour)"
          v-if="responsesDroppedCount"
        >
          {{ responsesDroppedCount }} dropped
        </span>
      </template>
      <div class="main__tabs-container" v-if="localAciveTab">
        <Spinner
          class="main__tabs-spinner"
          :is-loading="
            textAnalysisObject.showResponseListLoadingSpinner ||
            showResponseListLoadingSpinner
          "
          :componentStyle="true"
          message="loading response items"
          v-if="
            textAnalysisObject.showResponseListLoadingSpinner ||
            showResponseListLoadingSpinner
          "
        />
        <div
          class="main__tabs-container-options"
          style="display: flex; flex-direction: row"
        >
          <fieldset
            v-if="
              textAnalysisObject.activeTab == 'Sentiment' ||
              textAnalysisObject.selectedThemeUUIDs.length
            "
            class="main__tabs-container-options-highlight"
            style="font-size: 0.8rem; display: flex; z-index: 0"
          >
            <legend>Select highlight options</legend>
            <div
              class="checkbox-group"
              v-for="(option, index) in tabHighlightFilters[localAciveTab]"
              :key="`highlight-option-${option.id}-${index}`"
            >
              <input
                type="checkbox"
                :id="`highlight-option-${option.id}`"
                :value="option.id"
                v-model="tabHighlightFiltersSelected[localAciveTab]"
              />
              <label
                :for="`highlight-option-${option.id}`"
                :data-parent="$options.name"
                style="font-size: 0.8rem"
              >
                {{ translate(option.label, $options.name) }}
              </label>
            </div>
          </fieldset>
        </div>

        <template v-for="tab in tabs">
          <component
            :key="'tab-' + tab"
            :ref="'tab-' + tab"
            :is="tab"
            :propReadOnly="propReadOnly"
            :textAnalysisObject="textAnalysisObject"
            :hasFetchedLemmas="hasFetchedLemmas"
            :hasFetchedSentiments="hasFetchedSentiments"
            :hasFetchedSentences="hasFetchedSentences"
            :sortedResponsesLength="sortedResponsesLength"
            :includeSmartCoverage="includeSmartCoverage"
            :highlight-filters="tabHighlightFiltersSelected[localAciveTab]"
            :sortedResponsesProp="sortedResponsesProp"
            @showSentimentDetails="setShowSentimentDetails($event)"
            :sortedThemes="sortedThemes"
            :datasetIDQuestions="datasetIDQuestions"
            :show="localAciveTab.toLowerCase() == tab.toLowerCase()"
            v-if="localAciveTab.toLowerCase() == tab.toLowerCase()"
            @runSortAndSelect="$emit('runSortAndSelect')"
            @updateTextObject="$emit('updateTextObject', $event)"
            @updateTheseThemes="$emit('updateTheseThemes', $event)"
          >
          </component>
        </template>
      </div>
    </Accordion>
  </section>
</template>

<script>
// Components
import Search from "./Components/Search.vue"
import Sentiment from "./ProjectAnalysisTextMainSentiment.vue"
import Themes from "./ProjectAnalysisTextMainThemes.vue"
import Accordion from "@/components/UI/Accordion.vue"
import SwitchToggle from "@/components/UI/SwitchToggle.vue"
import MessageBlock from "@/components/UI/Message/MessageBlock.vue"
import SentimentsMiniGuide from "@/components/UserGuide/SentimentsMiniGuide.vue"
import ThematicAnalysisMiniGuide from "@/components/UserGuide/ThematicAnalysisMiniGuide.vue"
import SvgIconDecorative from "@/components/UI/Svg/SvgIconDecorative.vue"

import {
  PigeonDocNodeModel,
  PositiveNegativeBarChart
} from "@pigeonline/pigeondoc"

// Mixins
import textAnalysisMixin from "../Mixins/textAnalysisMixin.js"
import SelectTextMixin from "./Mixins/selectTextMixin.js"

// Consts
import { HIGHLIGHT_TYPES } from "./Utils/constants.js"
//import InlineEditTextInputVue from '../../../../UI/InlineEditTextInput.vue'

const SENTIMENT_FILTER_BY = [
  "Strongly positive",
  "Positive",
  "Neutral",
  "Negative",
  "Strongly negative"
]
//const SIMILARITY_SCORE_FILTER_BY = []

export default {
  name: "ProjectAnalysisTextMain",
  mixins: [textAnalysisMixin, SelectTextMixin],
  components: {
    Search,
    Themes,
    Sentiment,
    Accordion,
    SwitchToggle,
    PositiveNegativeBarChart,
    MessageBlock,
    SentimentsMiniGuide,
    ThematicAnalysisMiniGuide,
    SvgIconDecorative
  },
  props: {
    datasetIDQuestions: {
      default: () => [],
      type: Array
    },
    textAnalysisObject: {
      type: Object,
      required: false
    },
    sortedResponsesProp: {
      type: Array,
      required: false,
      default: () => []
    },
    hasFetchedLemmas: {
      type: Boolean,
      required: false,
      default: () => false
    },
    hasFetchedSentiments: {
      type: Boolean,
      required: false,
      default: () => false
    },
    hasFetchedSentences: {
      default: () => false,
      type: Boolean
    },
    includeSmartCoverage: {
      type: String,
      default: () => "exclude"
    },
    sortedResponsesLength: {
      type: Number,
      required: false
    },
    unsortedResponses: {
      type: Array,
      default: () => []
    },
    searchingForWordAssociations: {
      type: String,
      default: () => ""
    },
    sortedThemes: {
      type: Array,
      default: () => []
    },
    showResponseListLoadingSpinner: {
      type: Boolean,
      default: () => false
    },
    propReadOnly: {
      default: () => false,
      type: Boolean
    }
  },
  data() {
    return {
      tabs: ["Sentiment", "Themes"],
      localAciveTab: "Themes",
      responsesDroppedCount: 0,
      responsesBannedCount: 0,
      toggle: 0,
      selected: null, // selected client question
      isAutoGenerateModalVisible: false,
      isMounted: false,
      tabHighlightFilters: {
        Sentiment: [
          HIGHLIGHT_TYPES["pos-keyword"],
          HIGHLIGHT_TYPES["neg-keyword"]
        ],
        Themes: [
          HIGHLIGHT_TYPES["theme-keyword"],
          HIGHLIGHT_TYPES["theme-note"]
        ]
      },
      tabHighlightFiltersSelected: {
        //Sentiment: ["pos-keyword", "neg-keyword"],
        Sentiment: [],
        Themes: ["theme-keyword", "theme-note"]
      },
      revealSentimentTip: false,
      showSentimentsInfo: false,
      showThematicAnalysisInfo: false,
      // pop-up pagination
      sentimentPagination: {
        page: 1,
        limit: 2, //limit of how many are shown in a page
        totalPages: 4,
        startIndx: 0,
        endIndx: 0
      }
    }
  },
  computed: {
    activePosNegChartType() {
      return "Responses"
    },
    barChartResponsesFilteredAndSorted() {
      if (!this.sortedResponsesProp) {
        return []
      }
      return [...this.sortedResponsesProp].map((el) =>
        this.mapResponseToBarChartNodeItem(el)
      )
    },
    barChartNode() {
      if (
        !this.isMounted ||
        this.sortedResponsesProp.length < 1 ||
        (this.localAciveTab.toLowerCase() !== "sentiment" &&
          this.localAciveTab.toLowerCase() !== "themes")
      ) {
        return false
      }

      const title = this.activePosNegChartType
      const tableColumns = ["row", "response", "score", "sentiment", "themes"]
      const tableFilterByLabel = SENTIMENT_FILTER_BY

      const SCALE_DOWN_FACTOR = Math.ceil(
        Math.max(this.barChartResponsesFilteredAndSorted.length) / 1000
      )

      let shortenedData = []
      let filteredData = this.deepCloneObj(
        this.barChartResponsesFilteredAndSorted
      )
      if (this.barChartResponsesFilteredAndSorted.length > 8000) {
        shortenedData = filteredData.filter(
          (_, index) => index % SCALE_DOWN_FACTOR === 0
        )
      } else {
        shortenedData = filteredData
      }

      // PigeonDoc isn't optimized to use both data and data_ALL due to bugs
      return new PigeonDocNodeModel({
        type: "positiveNegativeBarChart",
        content: {
          title: `${title}`,
          data: shortenedData,
          data__ALL: shortenedData
        },
        meta: {
          veryPositiveColour: "var(--very-positive-soft)",
          positiveColour: "var(--somewhat-positive-soft)",
          neutralColour: "var(--neutral-soft)",
          negativeColour: "var(--somewhat-negative-soft)",
          veryNegativeColour: "var(--very-negative-soft)",
          highlightColour: "#56b4e9",
          selectedColour: "#0072b2",
          headingLevel: "h4",
          hasFetchedSentiments: this.hasFetchedSentiments,
          includeSentiments: this.textAnalysisObject.includeSentiments,
          isSentimentChart: true,
          isSentimentMode: this.localAciveTab.toLowerCase() == "sentiment",
          table: {
            columns: tableColumns,
            filterByLabel: tableFilterByLabel
          }
        }
      })
    },
    responsesCount() {
      return this.sortedResponsesProp.length
    },
    responsesCountTotal() {
      return this.sortedResponsesLength
    }
  },
  mounted() {
    this.isMounted = true
    if (this.textAnalysisObject) {
      this.updateLocalActiveTab()
      if (
        this.textAnalysisObject.selectedTextQuestion &&
        this.textAnalysisObject.selectedTextQuestion._id
      ) {
        this.selected = this.textAnalysisObject.selectedTextQuestion._id.$oid
      }
      if (
        this.localAciveTab == "Themes" ||
        (this.sortedThemes && this.sortedThemes.length)
      ) {
        this.$refs["switch-toggle"].value = 1
      }
      this.updateCounts()
    }
    if (!this.hasFetchedLemmas) {
      this.$refs["switch-toggle"].value = 1
    }
  },
  methods: {
    updateCounts() {
      if (!this.textAnalysisObject.selectedThemeUUIDs.length) {
        return 0
      }
      if (!this.sortedThemes.length) {
        return 0
      }
      const relevantThemes = this.sortedThemes.filter((theme) =>
        this.textAnalysisObject.selectedThemeUUIDs.includes(theme._id)
      )
      let relevantDropped = []
      for (let i = 0; i < relevantThemes.length; i++) {
        relevantDropped = relevantDropped.concat(relevantThemes[i].dropped)
      }
      this.responsesDroppedCount = this.sortedResponsesProp.filter((response) =>
        relevantDropped.includes(response.id)
      ).length

      // Update banned
      if (!this.textAnalysisObject.bannedComments.length) {
        return 0
      }
      const bannedCount = this.sortedResponsesProp.filter((response) =>
        this.textAnalysisObject.bannedComments.includes(response.id)
      ).length
      this.responsesBannedCount = bannedCount
    },
    updateLocalActiveTab() {
      this.localAciveTab = this.textAnalysisObject.activeTab
    },
    roundToTwo(num) {
      return +(Math.round(num + "e+2") + "e-2")
    },
    updateTextObject(cleanedProject) {
      let object = {
        project: cleanedProject,
        scroll: "main"
      }
      this.$emit("updateTextObject", object)
    },
    refreshThemesFromParent() {
      if (this.$refs.tabs - Sentiment) {
        this.$refs.tabs - Sentiment.refreshThemesFromParent()
      }
    },
    deepCloneObj(obj) {
      // deep clones an object using JSON stringify (data loss might occur)
      if (Array.isArray(obj)) {
        return obj.map((item) => JSON.parse(JSON.stringify(item)))
      } else if (typeof obj == "object") {
        return JSON.parse(JSON.stringify(obj))
      }
    },
    mapResponseToBarChartNodeItem(el) {
      let default_score = null
      if (
        el.response_sentiment &&
        typeof el.response_sentiment.compound_en !== "undefined"
      ) {
        default_score = el.response_sentiment.compound_en
      }
      if (this.textAnalysisObject.adjustedSentiments) {
        const adjustedSentiment =
          this.textAnalysisObject.adjustedSentiments.filter(
            (val) => val.responseId == el.id
          )

        if (adjustedSentiment != null && adjustedSentiment.length) {
          default_score = adjustedSentiment[0].adjustedSentiment
        }
      }

      // if (default_score == null) {
      //   return {}
      // }
      return {
        id: el.id,
        responseID: el.row_index,
        response: el.response,
        score: default_score,
        label: this.getScoreLabel(default_score),
        themes: this.getMatchingThemes(el)
      }
    },
    shouldRenderPosNegChart(bool) {
      if (!bool) return
      if (!this.$refs["pos-neg-chart"]) return
      this.$refs["pos-neg-chart"].renderChart()
    },
    getScoreLabel(score) {
      let thisScore = ""
      if (score >= 0.75) thisScore = "Strongly positive"
      if (score < 0.75) thisScore = "Positive"
      if (score < 0.25) thisScore = "Neutral"
      if (score <= -0.25) thisScore = "Negative"
      if (score <= -0.75) thisScore = "Strongly negative"
      return thisScore
    },

    getMatchingThemes(el) {
      let matchingThemes = []
      let moreThanOneTheme = false
      let matchingThemeNames = ""
      if (this.sortedThemes.length) {
        const themes = this.sortedThemes
        let newString = el.response.trim().toLowerCase()
        for (let i = 0; i < themes.length; i++) {
          for (let x = 0; x < themes[i].keywords.length; x++) {
            if (newString.includes(themes[i].keywords[x])) {
              let matchingTheme = {
                theme: themes[i],
                index: i,
                selected: false
              }
              if (
                this.textAnalysisObject.selectedThemeUUIDs &&
                this.textAnalysisObject.selectedThemeUUIDs.includes(
                  themes[i]._id
                )
              ) {
                matchingTheme.theme.selected = true
              } else {
                matchingTheme.theme.selected = false
              }
              let foundMatch = false
              for (let y = 0; y < matchingThemes.length; y++) {
                if (matchingThemes[y].theme._id == themes[i]._id) {
                  foundMatch = true
                  break
                }
              }
              for (let y = 0; y < themes[i].dropped.length; y++) {
                if (themes[i].dropped[y] == el.id) {
                  foundMatch = true
                  break
                }
              }
              if (!foundMatch) {
                matchingThemes.push(matchingTheme)
                if (moreThanOneTheme == true) {
                  matchingThemeNames = matchingThemeNames + ", "
                } else {
                  moreThanOneTheme = true
                }
                matchingThemeNames = matchingThemeNames + themes[i].name
              }

              break
            }
          }

          if (themes[i].notes.length) {
            for (let y = 0; y < themes[i].notes.length; y++) {
              if (
                themes[i].notes[y].responseId &&
                themes[i].notes[y].responseId.includes(el.id)
              ) {
                let matchingTheme = {
                  theme: themes[i],
                  index: i,
                  selected: false
                }
                if (
                  this.textAnalysisObject.selectedThemeUUIDs &&
                  this.textAnalysisObject.selectedThemeUUIDs.includes(
                    themes[i]._id
                  )
                ) {
                  matchingTheme.theme.selected = true
                } else {
                  matchingTheme.theme.selected = false
                }
                let foundMatch = false
                for (let y = 0; y < matchingThemes.length; y++) {
                  if (matchingThemes[y].theme._id == themes[i]._id) {
                    foundMatch = true
                    break
                  }
                }
                if (!foundMatch) {
                  matchingThemes.push(matchingTheme)
                  if (moreThanOneTheme == true) {
                    matchingThemeNames = matchingThemeNames + ", "
                  } else {
                    moreThanOneTheme = true
                  }
                  matchingThemeNames = matchingThemeNames + themes[i].name
                }
                break
              }
            }
          }
        }
      }

      return matchingThemeNames
    },

    /* Switch toggle methods */
    async handleToggleUpdate() {
      let tab = ""
      if (this.localAciveTab == "Sentiment") {
        tab = "Themes"
      } else {
        tab = "Sentiment"
      }
      if (tab) {
        this.localAciveTab = tab
        await this.setActiveTab(tab)
      }
    },

    showSentimentTip() {
      this.revealSentimentTip = !this.revealSentimentTip
    },
    onClickNextPage() {
      if (this.sentimentPagination.page === this.sentimentPagination.totalPages)
        return
      this.sentimentPagination.page = this.sentimentPagination.page + 1
      this.setPaginationDetails(
        this.sentimentPagination.startIndx + this.sentimentPagination.limit,
        this.sentimentPagination.endIndx + this.sentimentPagination.limit
      )
    },
    onClickPrevPage() {
      if (this.sentimentPagination.page === 1) return
      this.sentimentPagination.page = this.sentimentPagination.page - 1
      this.setPaginationDetails(
        this.sentimentPagination.startIndx - this.sentimentPagination.limit,
        this.sentimentPagination.endIndx - this.sentimentPagination.limit
      )
    },
    setPaginationDetails(start, end) {
      this.sentimentPagination.startIndx = start
      this.sentimentPagination.endIndx = end
    },
    setShowSentimentDetails(val) {
      if (val) {
        this.showSentimentsInfo = true
      }
    }
  },
  watch: {
    localAciveTab: function (val) {
      if (!val) return
      if (val === "Sentiment" && this.toggle === 1)
        this.$refs["switch-toggle"].value = 0
      if (val === "Themes" && this.toggle === 0)
        this.$refs["switch-toggle"].value = 1
    },
    textAnalysisObject: function (val) {
      if (val) {
        if (
          this.textAnalysisObject.selectedTextQuestion &&
          this.textAnalysisObject.selectedTextQuestion._id.$oid
        ) {
          this.selected = this.textAnalysisObject.selectedTextQuestion._id.$oid
        } else {
          this.selected = null
        }
        this.updateCounts()
      }
    }
  }
}
</script>
