<template>
  <div>
    <div class="modal-mask" v-show="show"></div>
    <div
      class="modal-container light-scroll-bar"
      role="dialog"
      ref="trap-container"
      tabindex="-1"
      :aria-labelledby="`${uuid}-header`"
      :class="wideStatus"
      :style="
        thinFormat
          ? 'width: 50vw; border-color: var(--outline-colour);border: 1px solid transparent;'
          : ''
      "
      :hidden="!show"
      :id="`${uuid}-container`"
    >
      <div class="modal-close">
        <component
          :style="isAutoSuggest || isEditThemes ? 'width: 100%' : ''"
          class="modal-header"
          :is="headerTag"
          :id="`${uuid}-header`"
        >
          <slot name="header"></slot>
        </component>
        <slot name="close">
          <ButtonIconOnly
            icon="remove"
            class="modal-close-btn"
            @click-handler="close"
          >
            <VisibleText>close</VisibleText>
          </ButtonIconOnly>
        </slot>
      </div>

      <div class="modal-body">
        <MessageBlock
          class="modal-message-block"
          :message-type="statusMessage.messageType"
          v-if="statusMessage.messageType"
        >
          {{ statusMessage.message }}
        </MessageBlock>
        <slot>Modal body</slot>
      </div>

      <div class="modal-footer">
        <slot name="footer"> </slot>
      </div>
    </div>
  </div>
</template>

<script>
import ButtonIconOnly from "@/components/UI/Button/ButtonIconOnly.vue"
import MessageBlock from "@/components/UI/Message/MessageBlock.vue"
import KeyboardTrapMixin from "@/utils/mixins/keyboardTrapMixin.js"

export default {
  name: "modal",
  mixins: [KeyboardTrapMixin],
  components: {
    ButtonIconOnly,
    MessageBlock
  },
  props: {
    headerTag: {
      default: () => "h2",
      type: String
    },
    show: {
      default: () => false,
      type: Boolean,
      required: true
    },
    // toggle to update keydown event listeners
    // useful for when modal re-renders or view updates
    resetListener: {
      default: () => false,
      type: Boolean
    },
    widerFormat: {
      default: () => false,
      type: Boolean
    },
    thinFormat: {
      default: () => false,
      type: Boolean
    },
    isAutoSuggest: {
      default: () => false,
      type: Boolean
    },
    isEditThemes: {
      default: () => false,
      type: Boolean
    }
  },
  data() {
    return {
      wideStatus: "",
      statusMessage: {
        messageType: null, // warning, error, information, success
        message: ""
      },
      uuid: this.$pigeonline.createUUID()
    }
  },
  created() {
    if (this.widerFormat) {
      this.wideStatus = "modal-container--widen"
    }
  },
  methods: {
    close() {
      this.$emit("close")
    },

    /* Message block methods */
    showMessage(messageType, message) {
      if (!["error", "success", "warning", "information"].includes(messageType))
        return
      this.statusMessage.messageType = messageType
      this.statusMessage.message = message
    },
    removeMessage() {
      this.statusMessage.messageType = null
      this.statusMessage.message = ""
    }
  },
  watch: {
    resetListener: function () {
      this.resetKeydownListener()
    },
    show: function (val) {
      if (val) this.removeMessage()
    }
  }
}
</script>

<style>
.modal-mask {
  position: fixed;
  z-index: 10000;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
}

.modal-container {
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  height: auto;
  justify-content: space-between;
  left: 0;
  margin: auto;
  max-height: 90vh;
  min-height: min-content;
  opacity: 1;
  overflow: hidden;
  padding: 0.25rem;
  position: fixed;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
  transition: opacity 250ms ease-out;
  visibility: visible;
  width: 80%;
  z-index: 10001;
}

.modal-container--widen {
  width: 80vw;
  height: 90vh;
}

.modal-container[hidden] {
  display: block;
  opacity: 0;
  transform: translateY(-50%);
  pointer-events: none;
  transition: opacity 150ms ease-in;
  visibility: hidden;
}

.modal-close {
  display: flex;
  justify-content: space-between;
  padding: 1rem;
}

.modal-body {
  flex: auto;
  margin: 0;
  overflow-x: hidden;
  overflow-y: auto;
  padding: 1rem;
}

.modal-message-block {
  margin: 0 auto !important;
  flex-basis: 100%;
}

.modal-footer {
  padding: 1rem;
}

@media only screen and (max-width: 440px) {
  .modal-container--widen {
    width: 100%;
  }
}
</style>
