<template>
  <Modal
    class="project-analysis-text-modal modal-themes-auto-generate"
    header-tag="h3"
    :ref="modalRef"
    :show="show"
    :isAutoSuggest="true"
    @close="closeModal"
    style="display: flex; flex-direction: column"
  >
    <template #header>
      <div
        style="
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          width: 100%;
        "
      >
        <h2 style="display: flex">
          <VisibleText>Generate themes</VisibleText>
        </h2>
        <button
          type="button"
          class="theme__helper-options-btn"
          @click="$emit('bannedKeywordsModalIsVisible')"
          style="margin-left: auto"
        >
          <SvgIconDecorative icon="ban" />
          <VisibleText style="font-size: 1rem"
            >Show banned keywords</VisibleText
          >
        </button>
      </div>
    </template>

    <div class="suggested-themes__wrapper" style="margin-bottom: 2em">
      <h3 class="suggested-themes__heading">
        <VisibleText>Themes you selected for saving</VisibleText>
      </h3>
      <div v-if="themeList && textAnalysisObject">
        <ThemeList
          class="suggested-themes__list"
          ref="themes=for-saving"
          type="keyword"
          :propReadOnly="propReadOnly"
          :sortedResponsesLength="sortedResponsesLength"
          :unsortedResponses="unsortedResponses"
          :textAnalysisObject="textAnalysisObject"
          :themes="themeList"
          :isListForSaving="true"
          :sortedThemes="sortedThemes"
          :isAutoGenerated="true"
          :includeSmartCoverage="includeSmartCoverage"
          :hasFetchedLemmas="hasFetchedLemmas"
          :hasFetchedSentences="hasFetchedSentences"
          :duplicateIndex="
            duplicateIndex.type == 'theme-list' ? duplicateIndex.index : -1
          "
          @update-theme-title="
            renameThemeTitle(
              themeList[$event.index],
              $event.title,
              'theme-list'
            )
          "
          @updatingSpinner="updateSpinner"
          @addToListForSaving="addToListForSaving"
          @removeFromListForSaving="removeFromListForSaving"
        />
      </div>
      <div v-if="!themeList || themeList.length === 0">
        <p>
          <VisibleText>
            Please select a suggested theme from below.
          </VisibleText>
        </p>
      </div>
    </div>
    <h3>
      <VisibleText> Generation settings </VisibleText>
    </h3>

    <div style="display: flex; flex-direction: row; margin-bottom: 2rem">
      <div style="display: flex; flex-direction: column; margin: 0 1rem">
        <div style="display: flex; flex-direction: row">
          <div style="margin-left: auto">
            <SvgIconDecorative
              style="height: 2rem; margin-right: 1rem"
              icon="statisticsGraph"
            />
          </div>
          <h4 style="font-size: 1rem">
            <VisibleText> Keyword topics </VisibleText>
          </h4>
        </div>

        <div style="display: flex; flex-direction: row; margin: 0 auto">
          <input
            type="checkbox"
            class="datasets__table-checkbox"
            name="and-logic"
            id="and-logic"
            style="width: 1.3rem; height: 1.3rem; margin-top: 0.5rem"
            v-model="mode.keyword_topics"
          />
          <label
            for="or-logic"
            style="
              text-align: right;
              margin-right: 1rem;
              margin-left: 1rem;
              font-size: 0.8rem;
            "
            class="form-label"
          >
            <VisibleText> LDA /LSI </VisibleText>
          </label>
        </div>
      </div>

      <div style="display: flex; flex-direction: column; margin: 0 1rem">
        <div style="display: flex; flex-direction: row">
          <div style="margin-left: auto">
            <SvgIconDecorative
              style="height: 2rem; margin-right: 1rem"
              icon="brain"
            />
          </div>
          <h4 style="font-size: 1rem">
            <VisibleText> GenAI-based topics </VisibleText>
          </h4>
        </div>

        <div
          style="display: flex; flex-direction: row; margin: 0 1rem"
          @click="checkForWarning()"
        >
          <input
            type="checkbox"
            class="datasets__table-checkbox"
            name="and-logic"
            id="and-logic"
            style="width: 1.3rem; height: 1.3rem; margin-top: 0.5rem"
            v-model="mode.sentence_topics"
            :disabled="!hasFetchedSentences"
          />

          <label
            for=" or-logic"
            style="
              text-align: right;
              margin-right: 1rem;
              margin-left: 1rem;
              font-size: 0.8rem;
            "
            class="form-label"
            :style="
              !hasFetchedSentences
                ? 'text-decoration: underline; cursor: pointer'
                : ''
            "
          >
            BERT + sentences
          </label>
        </div>
      </div>
      <div style="display: flex; flex-direction: column; margin: 0 1rem">
        <div style="display: flex; flex-direction: row; margin-right: auto">
          <div style="margin-left: auto">
            <SvgIconDecorative
              style="height: 1.8rem; margin-right: 1rem"
              icon="customWrench"
            />
          </div>
          <h4 style="font-size: 1rem">
            <VisibleText> Custom text models </VisibleText>
          </h4>
        </div>

        <div style="display: flex; flex-direction: row">
          <div style="display: flex; flex-direction: column">
            <div style="display: flex; flex-direction: row">
              <input
                type="checkbox"
                class="datasets__table-checkbox"
                name="and-logic"
                id="and-logic"
                style="width: 1.3rem; height: 1.3rem; margin-top: 0.5rem"
                v-model="mode.private_custom_topics"
              />
              <label
                for="or-logic"
                style="
                  text-align: right;
                  margin-right: 1rem;
                  margin-left: 1rem;
                  font-size: 0.8rem;
                "
                class="form-label"
              >
                My models
              </label>
            </div>
          </div>
          <div style="display: flex; flex-direction: column">
            <div style="display: flex; flex-direction: row">
              <input
                type="checkbox"
                class="datasets__table-checkbox"
                name="and-logic"
                id="and-logic"
                style="width: 1.3rem; height: 1.3rem; margin-top: 0.5rem"
                v-model="mode.public_custom_topics"
              />
              <label
                for="or-logic"
                style="
                  text-align: right;
                  margin-right: 1rem;
                  margin-left: 1rem;
                  font-size: 0.8rem;
                "
                class="form-label"
              >
                Public models
              </label>
            </div>
          </div>
        </div>
      </div>

      <div
        v-if="suggestedThemes && suggestedThemes.length"
        class="suggested-themes__options"
      >
        <button
          class="suggested-themes__empty-auto-generate-btn btn-default"
          role="button"
          @click.stop="autoGenerateThemes()"
        >
          <VisibleText>Re-generate new themes</VisibleText>
        </button>
      </div>
    </div>
    <div
      v-if="showSentenceWarning"
      style="
        font-size: 0.9rem;
        margin-bottom: 1rem;
        margin-top: -1rem;
        padding: 1rem;
        width: 80%;
      "
      class="message__block message__block--warning"
      @click="checkForWarning()"
    >
      <VisibleText>
        Note that sentence-based AI requires you to preprocess sentences. You
        can do this by closing this window and clicking AI Options beside
        'Generate themes' A banner will appear above. There, you can press
        'fetch' beside 'sentences not preprocessed'.
      </VisibleText>
    </div>
    <!-- Generated Themes -->
    <div class="suggested-themes__wrapper">
      <div class="suggested-themes__loading" v-if="isLoading">
        <p class="suggested-themes__loading-text">
          <VisibleText>Generating themes... please wait a minute.</VisibleText>
          <span class="suggested-themes__loading-spinner spinner"></span>
        </p>
      </div>
      <div class="suggested-themes__loading" v-if="isSaving">
        <p class="suggested-themes__loading-text">
          <VisibleText>Saving...</VisibleText>
          <span class="suggested-themes__loading-spinner spinner"></span>
        </p>
      </div>
      <div v-if="!isLoading && !isSaving">
        <div
          class="suggested-themes__empty"
          v-if="!suggestedThemes || suggestedThemes.length === 0"
        >
          <button
            class="suggested-themes__empty-auto-generate-btn btn-default"
            role="button"
            @click.stop="autoGenerateThemes()"
          >
            <VisibleText>Generate themes</VisibleText>
          </button>
        </div>
        <div v-else>
          <h3 class="suggested-themes__heading">
            <VisibleText>Suggested themes</VisibleText>
          </h3>
          <ThemeList
            v-if="suggestedThemes && textAnalysisObject"
            :textAnalysisObject="textAnalysisObject"
            :sortedResponsesLength="sortedResponsesLength"
            :unsortedResponses="unsortedResponses"
            :sortedThemes="sortedThemes"
            class="suggested-themes__list"
            ref="suggested-themes"
            :propReadOnly="propReadOnly"
            :refreshCoverage="refreshCoverage"
            type="keyword"
            :isAutoGenerated="true"
            :includeSmartCoverage="includeSmartCoverage"
            :hasFetchedLemmas="hasFetchedLemmas"
            :hasFetchedSentences="hasFetchedSentences"
            :themes="suggestedThemes"
            :duplicateIndex="
              duplicateIndex.type == 'suggested-theme'
                ? duplicateIndex.index
                : -1
            "
            @update-theme-title="
              renameThemeTitle(
                suggestedThemes[$event.index],
                $event.title,
                'suggested-themes'
              )
            "
            @addToListForSaving="addToListForSaving"
          />
        </div>
      </div>
    </div>

    <template #footer>
      <div class="modal-footer__options">
        <button
          class="btn-save btn-default"
          role="button"
          @click.stop="addToSavedThemes"
          :disabled="
            !unsavedChanges ||
            textAnalysisObject.showSpinner ||
            awaitingLoading ||
            propReadOnly
          "
        >
          <div v-if="!awaitingLoading">
            <VisibleText>Save changes</VisibleText>
          </div>
          <div v-else>
            <VisibleText>Wait for coverage to complete</VisibleText>
          </div>
        </button>
        <div v-if="!themeList || themeList.length === 0">
          You need at least one theme selected for saving.
        </div>
      </div>
    </template>
  </Modal>
</template>

<script>
// Mixins
import ThemeListMixin from "../Mixins/themeListMixin.js"
import themeColorsMixin from "../../Mixins/themeColorsMixin.js"
import textAnalysisMixin from "../../Mixins/textAnalysisMixin.js"
import SvgIconDecorative from "@/components/UI/Svg/SvgIconDecorative.vue"

export default {
  name: "ThemesAutoGenerate",
  mixins: [ThemeListMixin, textAnalysisMixin, themeColorsMixin],
  components: { SvgIconDecorative },
  props: {
    show: {
      default: () => false,
      type: Boolean
    },
    textAnalysisObject: {
      type: Object,
      required: false,
      default: () => {}
    },
    sortedResponsesLength: {
      type: Number,
      required: false
    },
    hasFetchedLemmas: {
      type: Boolean,
      required: false,
      default: () => false
    },
    hasFetchedSentences: {
      type: Boolean,
      default: () => false
    },
    includeSmartCoverage: {
      type: String,
      default: () => "exclude"
    },
    sortedThemes: {
      type: Array,
      default: () => []
    },
    unsortedResponses: {
      type: Array,
      default: () => []
    },
    propReadOnly: {
      default: () => false,
      type: Boolean
    }
  },
  data() {
    return {
      isLoading: false,
      isSaving: false,
      suggestedThemes: [],
      duplicateIndex: { type: "", index: -1 },
      refreshCoverage: false,
      spinnerQueue: [],
      mode: {
        keyword_topics: true,
        sentence_topics: false,
        private_custom_topics: true,
        public_custom_topics: false,
        include_custom_sentences: false
      },
      showSentenceWarning: false
    }
  },
  computed: {
    awaitingLoading() {
      if (this.spinnerQueue.length) {
        return true
      }
      return false
    }
  },
  methods: {
    refreshThemesFromParent() {
      this.themeList = this.deepCloneObj(this.sortedThemes)
    },
    updateTextObject(cleanedProject) {
      let object = {
        project: cleanedProject,
        scroll: "themes"
      }
      this.$emit("updateTextObject", object)
    },
    updateSpinner(val) {
      this.spinnerQueue = val
    },
    getConsolidatedThemeTitles() {
      // for checking duplicates
      return [...this.themeList, ...this.suggestedThemes].map((el) =>
        el.name.trim().toLowerCase()
      )
    },
    async autoGenerateThemes() {
      this.isLoading = true
      this.suggestedThemes = []
      let useThisID = this.textAnalysisObject.selectedTextQuestion._id
      if (useThisID.$oid) {
        useThisID = useThisID.$oid
      }
      let all_modes = []
      if (this.mode.keyword_topics) {
        all_modes.push("keyword_topics")
      }
      if (this.mode.sentence_topics) {
        all_modes.push("sentence_topics")
      }
      if (this.mode.private_custom_topics) {
        all_modes.push("private_custom_topics")
      }
      if (this.mode.public_custom_topics) {
        all_modes.push("public_custom_topics")
      }
      try {
        let smart_coverage = false
        if (this.includeSmartCoverage === "include") {
          smart_coverage = true
        }
        let all_suggested_themes = []
        for (let i = 0; i < all_modes.length; i++) {
          if (all_modes[i] == "sentence_topics") {
            const new_suggested_themes = await this.generateBertSentenceTopics({
              questionId: useThisID,
              filter_params: this.textAnalysisObject.filterParams,
              smart_coverage: smart_coverage
            })
            all_suggested_themes =
              all_suggested_themes.concat(new_suggested_themes)
          } else {
            const new_suggested_themes = await this.generateTextThemes({
              datasetId: this.textAnalysisObject.datasetId,
              questionId: useThisID,
              filter_params: this.textAnalysisObject.filterParams,
              smart_coverage: smart_coverage,
              mode: all_modes[i]
            })
            all_suggested_themes =
              all_suggested_themes.concat(new_suggested_themes)
          }
        }
        this.suggestedThemes = all_suggested_themes

        if (this.suggestedThemes.length === 0) {
          this.showModalMessage(
            "error",
            "Sorry, we could not find any themes to suggest. Please try using a different dataset or create themes manually."
          )
          return
        }
        // validate duplicate theme titles
        this.suggestedThemes.map(
          (el) =>
            (el.name = this.getValidatedThemeTitle(
              el.name,
              el.name,
              this.getConsolidatedThemeTitles()
            ))
        )
      } catch (e) {
        this.showModalMessage(
          "error",
          "An error occured processing your request. Please try again later."
        )
      } finally {
        this.isLoading = false
      }
    },
    async renameThemeTitle(theme, title, type) {
      if (this.suggestedThemes && type == "suggested-themes") {
        let themes = this.deepCloneObj(this.suggestedThemes)
        if (typeof title !== "string" || title.trim() === "") {
          title = "unnamed theme"
        }

        let matchingIndex = -1
        for (let i = 0; i < themes.length; i++) {
          if (themes[i]._id === theme._id) {
            matchingIndex = i
          }
        }
        let foundExisting = false
        for (let i = 0; i < this.sortedThemes.length; i++) {
          if (
            this.sortedThemes[i].name &&
            this.sortedThemes[i].name.toLowerCase() == title.toLowerCase()
          ) {
            foundExisting = true
          }
        }
        if (matchingIndex > -1 && !foundExisting) {
          themes[matchingIndex].name = title
          this.suggestedThemes = themes
        }
        if (matchingIndex > -1 && foundExisting) {
          this.duplicateIndex = {
            type: "suggested-theme",
            index: matchingIndex
          }
          setTimeout(() => {
            this.duplicateIndex = { type: "", index: -1 }
          }, 6000)
        }
      }

      if (this.themeList && type == "theme-list") {
        let themes = this.deepCloneObj(this.themeList)
        if (typeof title !== "string" || title.trim() === "") {
          title = "unnamed theme"
        }

        let matchingIndex = -1
        for (let i = 0; i < themes.length; i++) {
          if (themes[i]._id === theme._id) {
            matchingIndex = i
          }
        }
        let foundExisting = false
        for (let i = 0; i < this.sortedThemes.length; i++) {
          if (
            this.sortedThemes[i].name &&
            themes[i].name.toLowerCase() == title.toLowerCase()
          ) {
            foundExisting = true
          }
        }
        if (matchingIndex > -1 && !foundExisting) {
          themes[matchingIndex].name = title
          this.themeList = themes
        }
        if (matchingIndex > -1 && foundExisting) {
          this.duplicateIndex = { type: "theme-list", index: matchingIndex }
          setTimeout(() => {
            this.duplicateIndex = { type: "", index: -1 }
          }, 6000)
        }
      }
    },
    addToListForSaving(theme) {
      this.themeList.push(theme)
      for (let i = 0; i < this.suggestedThemes.length; i++) {
        if (this.suggestedThemes[i]._id === theme._id) {
          this.suggestedThemes.splice(i, 1)
        }
      }
    },
    removeFromListForSaving(theme) {
      for (let i = 0; i < this.themeList.length; i++) {
        if (this.themeList[i]._id === theme._id) {
          this.themeList.splice(i, 1)
        }
      }
      this.suggestedThemes.push(theme)
    },
    async addToSavedThemes() {
      this.isSaving = true
      let these_themes = []
      let found_colors = this.sortedThemes.map((theme) => theme.theme_color)
      for (let x = 0; x < this.themeList.length; x++) {
        if (this.themeList[x].is_autogenerated) {
          let this_theme = this.deepCloneObj(this.themeList[x])
          delete this_theme["is_autogenerated"]
          delete this_theme["_id"]
          let newColor = "#6a88aa"
          let assignedColor = await this.assignColor(found_colors)
          if (assignedColor) {
            newColor = assignedColor
          }
          this_theme.theme_color = newColor
          found_colors.push(newColor)
          these_themes.push(this_theme)
        }
      }
      if (these_themes.length) {
        let payload = {
          themes: these_themes,
          text_analysis_uuid: this.textAnalysisObject.uuid
        }
        const theseThemes = await this.TEXT_SERVICE.saveThemes(payload).then(
          (response) => {
            return response
          }
        )
        if (theseThemes.length) {
          let updatedThemeInfo = {
            themes: theseThemes,
            source: "ThemesAutogenerate"
          }
          this.$emit("updateTheseThemes", updatedThemeInfo)
          this.closeModal()
        }
      }
      this.isSaving = false

      // try {
      //   await this.saveTextThemes({
      //     themes: [...this.themeList],
      //     forceCompute: false,
      //     uuid: this.textAnalysisObject.uuid
      //   })
      //   this.themeList = []
      //   this.suggestedThemes = []

      //   // success
      //   this.showModalMessage("success", "Themes added successfully.")
      //   this.closeModal()
      //   // from mixin
      //   //this.fetchAllThemeResponses()
      // } catch (e) {
      //   // error
      //   this.showModalMessage(
      //     "error",
      //     "An error occured processing your request. Please try again later."
      //   )
      //   console.error("ThemesAutoGenerate.vue:addToSavedThemes: " + e)
      // } finally {
      //   // this.setShowSpinner(false)
      //   this.isSaving = false
      //   //this.refreshCoverage = true
      // }
    },
    checkForWarning() {
      if (!this.hasFetchedSentences) {
        this.showSentenceWarning = !this.showSentenceWarning
      }
    }
  },
  watch: {
    show: function (val) {
      if (val) {
        if (this.sortedThemes.length) {
          this.themeList = this.deepCloneObj(this.sortedThemes)
        }
      }
    }
  }
}
</script>
