import ProjectMixin from "@/components/Project/Mixins/projectMixin.js"
import moment from "moment"

const API_ERROR_MESSAGE =
  "An error occured processing your request. Please try again later."

export default {
  props: {
    propReadOnly: {
      default: () => false,
      type: Boolean
    }
  },
  mixins: [ProjectMixin],
  data() {
    return {
      emptyTextAnalysisObject: {
        uuid: "",
        datasetId: "",
        selectedTextQuestion: {},
        selectedResponseIds: [],
        filterParams: {},
        last_modified_on: "",
        // Banning and hiding
        bannedComments: [],
        bannedKeywords: [],
        hiddenComments: [],
        // Analysis options
        activeTab: "Themes",
        includeSentiments: "exclude",
        showCoverageDetails: "show",
        showAdvancedOptions: "hide",
        sentimentSortOrder: -1,
        themesSortOrder: "descending",
        themesSortType: "created",
        expandedResponses: [],
        includeSmartCoverage: "exclude",
        // Saving
        saved_node_ids: [],
        thisContentChanged: false,
        // Themes
        themes: [], // This has been removed from this object and moved to its own db collection as of 1.7.0
        // selectedThemeUUID: "", // Deprecated in 1.9.0 in favour of the below array
        selectedThemeUUIDs: [],
        // Sentiments
        adjustedSentiments: [],
        // When running a search
        search: {
          searchString: "",
          alsoFound: [],
          searchResultsIds: [],
          searchResults: [],
          excludeAssociations: false,
          wordAssociations: [],
          similarSentences: [],
          searchMode: "exact matches",
          isFetchingWordAssociations: false
        },
        // Spinners (why do we have two?)
        showResponseListLoadingSpinner: false,
        showSpinner: false
      }
    }
  },
  methods: {
    // ***
    // INITIAL LOGIC SECTION
    async fetchEmptyTextAnalysis() {
      try {
        return this.emptyTextAnalysisObject
      } catch (e) {
        throw new Error("textAnalysisMixin:summaryResults " + e.message)
      }
    },
    // This function will fetch all text responses from a question
    // It will include a "sentiment" object. This is an initial 'suggested' sentiment
    async fetchTextQuestionResponses(question, filter_params) {
      if (!filter_params) {
        filter_params = {}
      }
      if (!question._id) return []

      let proj_id = this.project.id
      if (this.project.id.$oid) {
        proj_id = this.project.id.$oid
      }
      const questionResponses =
        await this.DATASETS_SERVICE.getClientQuestionTextResponses({
          client_question_id: question._id,
          filter_params: filter_params,
          project_id: proj_id,
          text_analysis_uuid: this.textAnalysisObject.uuid
        })

      // ** Additional cleaning **
      // Remove NaN
      const clonedResponse = this.deepCloneObj(questionResponses)

      let NoNaNResponse = []
      if (clonedResponse.length) {
        for (let i = 0; i < clonedResponse.length; i++) {
          let foundNaN = false
          for (let x = 0; x < clonedResponse[i].response.length; x++) {
            if (
              clonedResponse[i].response[x].word == "nan" ||
              clonedResponse[i].response == "nan"
            ) {
              foundNaN = true
            }
          }
          if (foundNaN == false) {
            NoNaNResponse.push(clonedResponse[i])
          }
        }
      }
      return NoNaNResponse
    },

    // ***
    // SAVE USER INTERACTION LOGIC SECTION
    async processAndSave(saveParams) {
      if (saveParams) {
        if (saveParams.uuid) {
          // let preparedThemes = []
          for (let i = 0; i < this.project.textAnalysisObjects.length; i++) {
            if (this.project.textAnalysisObjects[i].uuid === saveParams.uuid) {
              if (saveParams.selectedTextQuestion) {
                if (
                  saveParams.selectedTextQuestion._id &&
                  saveParams.selectedTextQuestion._id.$oid
                ) {
                  saveParams.selectedTextQuestion._id =
                    saveParams.selectedTextQuestion._id.$oid
                }
                this.project.textAnalysisObjects[i].selectedTextQuestion =
                  saveParams.selectedTextQuestion
              }
              // moved from textAnalysisObject to separate DB object in 1.7.0, but can be removed during transition
              if (saveParams.themes) {
                this.textAnalysisObject.themes = []
                this.project.textAnalysisObjects[i].themes = []
              }

              // In 1.9.0 moved from a single theme to an array of themes
              if (saveParams.isChangingThemeUUID) {
                this.project.textAnalysisObjects[i].selectedThemeUUIDs =
                  saveParams.selectedThemeUUIDs
                this.textAnalysisObject.selectedThemeUUIDs =
                  saveParams.selectedThemeUUIDs
              }
              if (saveParams.isChangingThemeUUID) {
                this.project.textAnalysisObjects[i].isChangingThemeUUID =
                  saveParams.isChangingThemeUUID
                this.textAnalysisObject.isChangingThemeUUID =
                  saveParams.isChangingThemeUUID
              }

              if (saveParams.includeSentiments) {
                this.project.textAnalysisObjects[i].includeSentiments =
                  saveParams.includeSentiments
                this.textAnalysisObject.includeSentiments =
                  saveParams.includeSentiments
              }
              if (saveParams.showAdvancedOptions) {
                this.project.textAnalysisObjects[i].showAdvancedOptions =
                  saveParams.showAdvancedOptions
                this.textAnalysisObject.showAdvancedOptions =
                  saveParams.showAdvancedOptions
              }
              if (saveParams.showCoverageDetails) {
                this.project.textAnalysisObjects[i].showCoverageDetails =
                  saveParams.showCoverageDetails
                this.textAnalysisObject.showCoverageDetails =
                  saveParams.showCoverageDetails
              }
              if (saveParams.includeSmartCoverage) {
                this.project.textAnalysisObjects[i].includeSmartCoverage =
                  saveParams.includeSmartCoverage
                this.textAnalysisObject.includeSmartCoverage =
                  saveParams.includeSmartCoverage
              }
              if (saveParams.adjustedSentiments) {
                this.project.textAnalysisObjects[i].adjustedSentiments =
                  saveParams.adjustedSentiments
              }
              if (saveParams.allCharts) {
                this.project.textAnalysisObjects[i].allCharts =
                  saveParams.allCharts
              }

              if (saveParams.expandedResponses) {
                this.project.textAnalysisObjects[i].expandedResponses =
                  saveParams.expandedResponses
              }
              if (saveParams.bannedComments) {
                this.project.textAnalysisObjects[i].bannedComments =
                  saveParams.bannedComments
              }
              if (saveParams.hiddenComments) {
                this.project.textAnalysisObjects[i].hiddenComments =
                  saveParams.hiddenComments
              }
              if (saveParams.showSpinner) {
                // are we using this?
                this.project.textAnalysisObjects[i].showSpinner =
                  saveParams.showSpinner
              }
              if (saveParams.activeTab) {
                this.project.textAnalysisObjects[i].activeTab =
                  saveParams.activeTab
              }
              if (
                saveParams.sentimentSortOrder <= 0 ||
                saveParams.sentimentSortOrder > 0
              ) {
                this.project.textAnalysisObjects[i].sentimentSortOrder =
                  saveParams.sentimentSortOrder
                this.textAnalysisObject.sentimentSortOrder =
                  saveParams.sentimentSortOrder
              }
              if (saveParams.selectedResponseIds) {
                this.project.textAnalysisObjects[i].selectedResponseIds =
                  saveParams.selectedResponseIds
                this.textAnalysisObject.selectedResponseIds =
                  saveParams.selectedResponseIds
              }
              if (saveParams.bannedKeywords) {
                this.project.textAnalysisObjects[i].bannedKeywords =
                  saveParams.bannedKeywords
                this.textAnalysisObject.bannedKeywords =
                  saveParams.bannedKeywords
              }
              if (saveParams.search) {
                this.project.textAnalysisObjects[i].search = saveParams.search
                this.textAnalysisObject.search = saveParams.search
              }
            }
          }
          if (saveParams.runSortAndSelect) {
            this.emitSortAndSelect()
          }
          let skipUpdateEmit = false
          if (saveParams.skipUpdateEmit) {
            skipUpdateEmit = true
          }
          return await this.cleanAndSaveProject(skipUpdateEmit)
        }
      }
    },
    // Note that projectReportMixin and Project.vue have a function of the same name. To do: Rename
    async cleanAndSaveProject(skipUpdateEmit) {
      this.thisNum = this.thisNum + 1
      let cleanedProject = this.deepCloneObj(this.project)
      if (cleanedProject) {
        cleanedProject.id = this.project.id
        if (cleanedProject._id) {
          delete cleanedProject._id
        }
        if (!this.propReadOnly) {
          this.saveProject(cleanedProject)
        }

        // The child that calls this chain of saving functions should have this function, to tell the parent in ProjectAnalysisTextItem to update
        if (this.updateTextObject) {
          if (!skipUpdateEmit) {
            this.updateTextObject(cleanedProject)
          }
        }
        return "Success"
      }
    },
    async saveSelectedTextQuestion(question) {
      await this.processAndSave(question)
    },
    // Keeping to allow pre 1.7.0 themes to be removed
    async saveTextThemes(saveParams) {
      this.showModalMessage(null, null)
      await this.processAndSave(saveParams)
    },
    async saveDeletedTheme(theme_id) {
      if (theme_id.$oid) {
        theme_id = theme_id.$oid
      }

      let payload = {
        _id: theme_id,
        text_analysis_uuid: this.textAnalysisObject.uuid
      }
      await this.TEXT_SERVICE.deleteTextTheme(payload)
    },
    // Remove a note that has been added
    async saveTextThemeNotesRemoved(themeUUID, note) {
      for (let x = 0; x < this.sortedThemes.length; x++) {
        if (this.sortedThemes[x]._id == themeUUID) {
          // Remove the note

          let newTheme = this.deepCloneObj(this.sortedThemes[x])
          let newNotes = newTheme.notes.filter(
            (el) =>
              !(
                el.responseId === note.responseId &&
                el.startIndex === parseInt(note.startIndex) &&
                el.content == note.content
              )
          )
          newTheme.notes = newNotes
          let newThemes = []
          newThemes.push(newTheme)
          let payload = {
            themes: newThemes,
            text_analysis_uuid: this.textAnalysisObject.uuid
          }
          const theseThemes = await this.TEXT_SERVICE.saveThemes(payload).then(
            (response) => {
              return response
            }
          )
          if (theseThemes.length) {
            let updatedThemeInfo = {
              themes: theseThemes,
              source: "Container"
            }
            this.$emit("updateTheseThemes", updatedThemeInfo)
          }

          break
        }
      }
    },
    // Remove a keyword match, also called "dropped"
    saveTextThemeDroppedItem(themeUUID, responseId) {
      if (themeUUID && responseId) {
        for (let x = 0; x < this.sortedThemes.length; x++) {
          if (this.sortedThemes[x]._id == themeUUID) {
            const isFound = this.sortedThemes[x].dropped.includes(responseId)
            if (isFound) {
              this.sortedThemes[x].dropped = this.sortedThemes[
                x
              ].dropped.filter((item) => item !== responseId)
            } else {
              this.sortedThemes[x].dropped.push(responseId)
            }
            const saveParams = {
              forceCompute: false,
              uuid: this.textAnalysisObject.uuid,
              dropped: responseId
            }
            this.processAndSave(saveParams)
            break
          }
        }
      }
    },
    // used to allow text question to be changed. Can delete chain.
    async selectTextQuestion(question) {
      this.textAnalysisObject.selectedTextQuestion = question
      return this.textAnalysisObject.selectedTextQuestion
    },

    async includeExcludeSentiments(option) {
      if (!option) {
        return
      }
      this.textAnalysisObject.includeSentiments = option
      this.setIncludeSentiments(option)
      if (option == "exclude") {
        let tab = ""

        if (this.textAnalysisObject.activeTab == "Sentiment") {
          tab = "Themes"
          if (tab) {
            this.textAnalysisObject.activeTab = tab
            await this.setActiveTab(tab)
          }
        }
      }
    },

    async setIncludeSentiments(sentimentStatus) {
      const saveParams = {
        forceCompute: false,
        uuid: this.textAnalysisObject.uuid,
        includeSentiments: sentimentStatus
      }
      this.processAndSave(saveParams)
    },
    async setShowAdvancedOptions(advancedOption) {
      const saveParams = {
        forceCompute: false,
        uuid: this.textAnalysisObject.uuid,
        showAdvancedOptions: advancedOption
      }
      this.processAndSave(saveParams)
    },
    async setShowCoverageDetails(coverageOption) {
      const saveParams = {
        forceCompute: false,
        uuid: this.textAnalysisObject.uuid,
        showCoverageDetails: coverageOption
      }
      this.processAndSave(saveParams)
    },
    async setIncludeSmartCoverage(smartSearchOption) {
      const saveParams = {
        forceCompute: false,
        uuid: this.textAnalysisObject.uuid,
        includeSmartCoverage: smartSearchOption
      }
      this.processAndSave(saveParams)
    },
    async updateAllCharts(allCharts) {
      this.textAnalysisObject.allCharts = allCharts
      const saveParams = {
        forceCompute: false,
        uuid: this.textAnalysisObject.uuid,
        allCharts: allCharts
      }
      this.processAndSave(saveParams)
    },
    async adjustSentimentItem(sentimentUpdates) {
      let newSentiment = parseFloat(sentimentUpdates.newSentiment)
      if (
        sentimentUpdates.responseId &&
        newSentiment >= -1 &&
        newSentiment <= 1
      ) {
        let foundExistingMatch = false
        if (this.textAnalysisObject.adjustedSentiments.length) {
          for (
            let i = 0;
            i < this.textAnalysisObject.adjustedSentiments.length;
            i++
          ) {
            if (
              this.textAnalysisObject.adjustedSentiments[i].responseId ==
              sentimentUpdates.responseId
            ) {
              this.textAnalysisObject.adjustedSentiments[i].adjustedSentiment =
                newSentiment
              await this.saveAdjustedSentiments({
                adjustedSentiments: this.textAnalysisObject.adjustedSentiments,
                forceCompute: true,
                uuid: this.textAnalysisObject.uuid
              })
              foundExistingMatch = true
              break
            }
          }
        }
        if (foundExistingMatch == false) {
          let newSentimentObject = {
            responseId: sentimentUpdates.responseId,
            adjustedSentiment: newSentiment
          }
          this.textAnalysisObject.adjustedSentiments.push(newSentimentObject)
          await this.saveAdjustedSentiments({
            adjustedSentiments: this.textAnalysisObject.adjustedSentiments,
            forceCompute: true,
            uuid: this.textAnalysisObject.uuid
          })
        }
      }
    },
    async banResponseItem(responseId) {
      const bannedComments = [...this.textAnalysisObject.bannedComments]
      // const bannedComments__ORIGINAL = _.clone(bannedComments)

      let newId = responseId
      if (responseId.$oid) {
        newId = responseId
      }
      if (bannedComments.includes(newId)) return
      this.textAnalysisObject.bannedComments.push(newId)
      const saveParams = {
        forceCompute: false,
        uuid: this.textAnalysisObject.uuid,
        bannedComments: this.textAnalysisObject.bannedComments
      }
      const preparedAndSaved = await this.processAndSave(saveParams)
      if (preparedAndSaved) {
        this._alert(
          "Response successfully banned.",
          "Success",
          "success",
          "banResponseItem"
        )
      } else {
        this._alert(API_ERROR_MESSAGE, "Error", "error", "banResponseItem")
        throw new Error("textAnalysisMixin.js:banResponseItem: ")
      }
    },
    async setResponseAsExpanded(responseId, skipUpdateEmit) {
      if (!responseId) return

      if (this.textAnalysisObject.expandedResponses) {
        let found = false
        for (
          let i = 0;
          i < this.textAnalysisObject.expandedResponses.length;
          i++
        ) {
          if (this.textAnalysisObject.expandedResponses[i] == responseId) {
            found = true
          }
        }
        if (!found) {
          this.textAnalysisObject.expandedResponses.push(responseId)
        } else {
          this.textAnalysisObject.expandedResponses =
            this.textAnalysisObject.expandedResponses.filter(
              (response) => response !== responseId
            )
        }
      } else {
        this.textAnalysisObject.expandedResponses = []
        this.textAnalysisObject.expandedResponses.push(responseId)
      }

      const saveParams = {
        forceCompute: false,
        uuid: this.textAnalysisObject.uuid,
        expandedResponses: this.textAnalysisObject.expandedResponses,
        skipUpdateEmit: skipUpdateEmit
      }
      await this.processAndSave(saveParams)
    },
    async toggleResponseItemHide(responseId) {
      if (responseId) {
        let newId = responseId
        if (responseId.$oid) {
          newId = responseId.$oid
        }
        const hiddenComments = [...this.textAnalysisObject.hiddenComments]
        if (hiddenComments.includes(newId)) {
          hiddenComments.splice(hiddenComments.indexOf(newId), 1)
        } else {
          hiddenComments.push(newId)
        }
        this.textAnalysisObject.hiddenComments = hiddenComments
        const saveParams = {
          forceCompute: false,
          uuid: this.textAnalysisObject.uuid,
          hiddenComments: this.textAnalysisObject.hiddenComments
        }
        await this.processAndSave(saveParams)
      }
    },
    async saveBanKeyword(keyword) {
      if (
        this.textAnalysisObject.bannedKeywords.includes(keyword.toLowerCase())
      ) {
        this.textAnalysisObject.bannedKeywords =
          this.textAnalysisObject.bannedKeywords.filter(
            (obj) => obj !== keyword
          )
      } else {
        this.textAnalysisObject.bannedKeywords.push(keyword)
      }
      const saveParams = {
        forceCompute: false,
        uuid: this.textAnalysisObject.uuid,
        bannedKeywords: this.textAnalysisObject.bannedKeywords
      }
      this.processAndSave(saveParams)
    },
    async setSearch(obj) {
      this.textAnalysisObject.search = {
        searchString: "",
        alsoFound: [],
        searchResults: [],
        searchResultsIds: [],
        excludeAssociations: false,
        wordAssociations: [],
        similarSentences: [],
        searchMode: "exact matches",
        isFetchingWordAssociations: false
      }
      if (obj.searchString) {
        this.textAnalysisObject.search.searchString = obj.searchString
      }
      if (obj.searchResultsIds) {
        this.textAnalysisObject.search.searchResultsIds = obj.searchResultsIds // not currently used by either regular search or similar sentences
      }
      if (obj.searchResults) {
        this.textAnalysisObject.search.searchResults = obj.searchResults
      }
      if (obj.similarSentences) {
        this.textAnalysisObject.search.similarSentences = obj.similarSentences
      }
      if (obj.searchMode) {
        this.textAnalysisObject.search.searchMode = obj.searchMode
      }
      const saveParams = {
        forceCompute: false,
        uuid: this.textAnalysisObject.uuid,
        search: this.textAnalysisObject.search,
        skipUpdateEmit: true,
        runSortAndSelect: true
      }
      await this.processAndSave(saveParams)
    },
    async resetSearch() {
      this.textAnalysisObject.search = {
        searchString: "",
        alsoFound: [],
        searchResults: [],
        searchResultsIds: [],
        excludeAssociations: false,
        wordAssociations: [],
        similarSentences: [],
        searchMode: "exact matches",
        isFetchingWordAssociations: false
      }
      const saveParams = {
        forceCompute: false,
        uuid: this.textAnalysisObject.uuid,
        search: this.textAnalysisObject.search,
        skipUpdateEmit: true
      }
      await this.processAndSave(saveParams)
    },
    async setShowResponseListLoadingSpinner(bool) {
      this.textAnalysisObject.showResponseListLoadingSpinner = bool
    },
    async setShowSpinner(bool) {
      this.textAnalysisObject.setShowSpinner = bool
    },
    async setActiveTab(tab) {
      const saveParams = {
        forceCompute: false,
        uuid: this.textAnalysisObject.uuid,
        activeTab: tab
      }
      await this.processAndSave(saveParams)
      this.textAnalysisObject.activeTab = saveParams.activeTab
    },

    async setSelectedThemeUUIDs(uuids) {
      let useTheseUUIDs = uuids
      if (!uuids) {
        useTheseUUIDs = []
      }
      const saveParams = {
        forceCompute: false,
        uuid: this.textAnalysisObject.uuid,
        selectedThemeUUIDs: useTheseUUIDs,
        isChangingThemeUUID: true,
        skipUpdateEmit: true
      }
      this.processAndSave(saveParams)
    },
    async setSentimentSortOrder(responses) {
      this.textAnalysisObject.sentimentSortOrder = responses
      const saveParams = {
        forceCompute: false,
        uuid: this.textAnalysisObject.uuid,
        sentimentSortOrder: responses
      }
      this.processAndSave(saveParams)
    },
    async setSelectedResponseIds(respondIds) {
      let useThese = []
      if (!respondIds || !respondIds.length) {
        useThese = []
      } else {
        useThese = respondIds
      }
      const saveParams = {
        forceCompute: false,
        uuid: this.textAnalysisObject.uuid,
        selectedResponseIds: useThese
      }
      this.processAndSave(saveParams)
    },
    async saveAdjustedSentiments(adjustedSentiments) {
      const saveParams = {
        forceCompute: false,
        uuid: this.textAnalysisObject.uuid,
        adjustedSentiments: adjustedSentiments.adjustedSentiments
      }
      this.processAndSave(saveParams)
    },
    selectionChanged(selection) {
      let filterByResponseId = []
      for (let x = 0; x < selection.length; x++) {
        let selectedId = selection[x].id
        if (selection[x].id.$oid) {
          selectedId = selection[x].id.$oid
        }
        filterByResponseId.push(selectedId)
      }
      this.setSelectedResponseIds(filterByResponseId)
    },
    setBannedKeywords(array) {
      const saveParams = {
        forceCompute: false,
        uuid: this.textAnalysisObject.uuid,
        bannedKeywords: array
      }
      this.processAndSave(saveParams)
    },
    setTextAnalysisObject(object) {
      this.textAnalysisObject = object
    },
    // ***
    // THEME LOGIC SECTION
    async generateTextThemes(prop) {
      try {
        const response = await this.TEXT_SERVICE.keywordGenerator({
          project_id: this.project.id,
          data_set_id: prop.datasetId,
          client_question_id: prop.questionId,
          text_analysis_uuid: this.textAnalysisObject.uuid,
          filter_params: prop.filter_params,
          smart_coverage: prop.smart_coverage,
          mode: prop.mode
        })
        const sortedThemes = this.deepCloneObj(this.sortedThemes)
        let found_colors = sortedThemes.map((theme) => theme.theme_color)
        const _map = async function (theme) {
          const keywords = this.filterBannedKeywords(theme[1].keywords)
          const keyword_matches = this.filterBannedKeywords(
            theme[1].keyword_matches
          )
          // Is this needed? This was added to 'fix' create new theme bug but that seems to be another issue
          let metadata = {}
          if (theme[1].topic_metadata) {
            metadata = theme[1].topic_metadata
          }

          let newColor = "#6a88aa"
          let assignedColor = await this.assignColor(found_colors)
          if (assignedColor) {
            newColor = assignedColor
          }
          let coverage = 0
          let coverage_count = 0
          if (theme[1].coverage && Object.keys(theme[1].coverage).length) {
            coverage = theme[1].coverage
            coverage_count = theme[1].coverage.total
          }
          let filtered_coverage = 0
          let filtered_coverage_count = 0
          let filtered_keyword_matches = []
          if (
            theme[1].filtered_coverage &&
            Object.keys(theme[1].filtered_coverage).length
          ) {
            filtered_coverage = theme[1].filtered_coverage
            filtered_coverage_count = theme[1].filtered_coverage.total
            filtered_keyword_matches = this.filterBannedKeywords(
              theme[1].filtered_keyword_matches
            )
          }
          return {
            name: theme[0],
            text_analysis_uuid: this.textAnalysisObject.uuid,
            keywords: keywords,
            keyword_matches: keyword_matches,
            filtered_keyword_matches: filtered_keyword_matches,
            coverage: coverage,
            coverage_count: coverage_count,
            coverage_queue: 0,
            filtered_coverage: filtered_coverage,
            filtered_coverage_count: filtered_coverage_count,
            sentiment: [],
            notes: [],
            theme_color: newColor,
            source: metadata,
            dropped: [],
            added: [],
            selected: false,
            is_autogenerated: true,
            _id: this.$pigeonline.createUUID(), // Temporary, deleted before saving. Used for selecting
            created_on: moment().valueOf()
          }
        }.bind(this)
        return await Promise.all(Object.entries(response).map(theme => _map(theme))) // eslint-disable-line
      } catch (e) {
        console.error(
          "ProjectAnalysisTextTheme.vue:generateThemes: " + e.message
        ) // eslint-disable-line
      }
    },
    // THEME LOGIC SECTION
    async generateBertSentenceTopics(prop) {
      try {
        const response = await this.TEXT_SERVICE.bertSentenceTopicsGenerator({
          client_question_id: prop.questionId,
          smart_coverage: prop.smart_coverage,
          filter_params: prop.filter_params
        })
        const sortedThemes = this.deepCloneObj(this.sortedThemes)
        let found_colors = sortedThemes.map(
          (existing_theme) => existing_theme.theme_color
        )
        const _map = async function (theme) {
          const this_theme = theme[1]
          const keywords = this.filterBannedKeywords(this_theme.keywords)
          const keyword_matches = this.filterBannedKeywords(
            this_theme.keyword_matches
          )

          // Is this needed? This was added to 'fix' create new theme bug but that seems to be another issue
          // let metadata = {}
          // if (theme[1].topic_metadata) {
          //   metadata = theme[1].topic_metadata
          // }

          let newColor = "#6a88aa"
          let assignedColor = await this.assignColor(found_colors)
          if (assignedColor) {
            newColor = assignedColor
          }
          let new_notes = []
          if (this_theme.responses) {
            for (let x = 0; x < this_theme.responses.length; x++) {
              for (
                let y = 0;
                y < this_theme.responses[x].sentences.length;
                y++
              ) {
                let note = {}
                note.content = this_theme.responses[x].sentences[y].text
                note.length =
                  this_theme.responses[x].sentences[y].end_idx -
                  this_theme.responses[x].sentences[y].start_idx
                note.responseId = this_theme.responses[x].response_id
                note.row_index = this_theme.responses[x].row_index
                note.source = {
                  questionId: prop.questionId
                }
                note.startIndex = this_theme.responses[x].sentences[y].start_idx
                note.text = ""
                new_notes.push(note)
              }
            }
          }
          let coverage = {}
          let coverage_count = 0
          if (this_theme.coverage && Object.keys(this_theme.coverage).length) {
            coverage = this_theme.coverage
            coverage_count = this_theme.coverage.total
          }
          let filtered_coverage = {}
          let filtered_coverage_count = 0
          let filtered_keyword_matches = []
          if (
            this_theme.filtered_coverage &&
            Object.keys(this_theme.filtered_coverage).length
          ) {
            filtered_coverage = this_theme.filtered_coverage
            filtered_coverage_count = this_theme.filtered_coverage.total
            filtered_keyword_matches = this.filterBannedKeywords(
              this_theme.filtered_keyword_matches
            )
          }
          return {
            name: this_theme.topic_name,
            text_analysis_uuid: this.textAnalysisObject.uuid,
            keywords: keywords,
            keyword_matches: keyword_matches,
            filtered_keyword_matches: filtered_keyword_matches,
            coverage: coverage,
            coverage_count: coverage_count,
            coverage_queue: 0,
            filtered_coverage: filtered_coverage,
            filtered_coverage_count: filtered_coverage_count,
            sentiment: [],
            notes: new_notes,
            theme_color: newColor,
            source: {
              source_type: "bert_sentence"
            },
            dropped: [],
            added: [],
            selected: false,
            is_autogenerated: true,
            _id: this.$pigeonline.createUUID(), // Temporary, deleted before saving. Used for selecting
            created_on: moment().valueOf()
          }
        }.bind(this)
        return await Promise.all(Object.entries(response).map(theme => _map(theme))) // eslint-disable-line
      } catch (e) {
        console.error(
          "ProjectAnalysisTextTheme.vue:generateThemes: " + e.message
        ) // eslint-disable-line
      }
    },
    async updateExistingThemeTitle(theme, title) {
      // if this works, merge with the below or have only one?
      if (this.sortedThemes) {
        let themes = this.deepCloneObj(this.sortedThemes)
        if (typeof title !== "string" || title.trim() === "") {
          title = "unnamed theme"
        }

        let foundIndex = -1
        let foundExisting = false
        for (let i = 0; i < themes.length; i++) {
          if (themes[i]._id === theme._id) {
            foundIndex = i
          }
          if (
            themes[i].name &&
            themes[i].name.toLowerCase() == title.toLowerCase()
          ) {
            foundExisting = true
          }
        }
        if (foundIndex > -1 && !foundExisting) {
          themes[foundIndex].name = title

          let these_themes = []
          these_themes.push(themes[foundIndex])
          await this.addToSavedThemesinTextTheme(these_themes)
        }
        if (
          foundIndex > -1 &&
          foundExisting &&
          !themes[foundIndex].is_proposed
        ) {
          this.duplicateIndex = foundIndex
          setTimeout(() => {
            this.duplicateIndex = -1
          }, 6000)
        }
      }
    },
    parseCoveragePercentage(theme, sortedResponsesLength) {
      let numerator = 0

      if (
        theme.filtered_coverage &&
        Object.keys(theme.filtered_coverage).length
      ) {
        if (theme.filtered_coverage_count > -1) {
          numerator = theme.filtered_coverage_count
        }
        let denominator = null
        if (theme) {
          denominator = sortedResponsesLength
        }
        return ((parseInt(numerator) / parseInt(denominator)) * 100).toFixed(1)
      } else {
        if (theme.coverage_count > -1) {
          numerator = theme.coverage_count
        }
        let denominator = null
        if (theme) {
          denominator = sortedResponsesLength
        }
        return ((parseInt(numerator) / parseInt(denominator)) * 100).toFixed(1)
      }
    },

    // ***
    // MAIN FILTERING LOGIC SECTION

    filterBannedKeywords(keywords) {
      if (!keywords || !keywords.length) {
        return []
      }
      if (
        !this.textAnalysisObject.bannedKeywords ||
        !this.textAnalysisObject.bannedKeywords.length
      ) {
        return keywords
      }
      return keywords.filter(
        (el) =>
          this.textAnalysisObject.bannedKeywords.indexOf(
            el.trim().toLowerCase()
          ) === -1
      )
    },
    sortTextResponses(responses) {
      if (!responses || !responses.length) {
        this.sortedResponses = []
        return []
      }
      if (
        this.hasFetchedSentiments &&
        this.textAnalysisObject.includeSentiments == "include"
      ) {
        let clonedResponses = this.deepCloneObj(responses)
        // move below to a filter - optimization TODO
        if (this.textAnalysisObject.adjustedSentiments) {
          for (
            let i = 0;
            i < this.textAnalysisObject.adjustedSentiments.length;
            i++
          ) {
            for (let x = 0; x < clonedResponses.length; x++) {
              if (
                this.textAnalysisObject.adjustedSentiments[i].responseId ==
                clonedResponses[x].id
              ) {
                clonedResponses[x].response_sentiment.compound_en =
                  this.textAnalysisObject.adjustedSentiments[
                    i
                  ].adjustedSentiment
                break
              }
            }
          }
        }
        if (this.textAnalysisObject.sentimentSortOrder < 0) {
          clonedResponses.sort(
            (a, b) =>
              a.response_sentiment.compound_en -
              b.response_sentiment.compound_en
          )
        }
        if (this.textAnalysisObject.sentimentSortOrder > 0) {
          clonedResponses.sort(
            (a, b) =>
              b.response_sentiment.compound_en -
              a.response_sentiment.compound_en
          )
        }
        if (this.textAnalysisObject.sentimentSortOrder == 0) {
          clonedResponses.sort((a, b) => a.row_index - b.row_index)
        }

        this.sortedResponses = clonedResponses
      } else {
        this.sortedResponses = responses.sort(
          (a, b) => a.row_index - b.row_index
        )
      }
    },

    async sortThemes() {
      if (!this.unsortedThemes || !this.unsortedThemes.length) {
        return []
      }
      let themes = this.deepCloneObj(this.unsortedThemes)

      if (this.textAnalysisObject.themesSortType == "created") {
        if (this.textAnalysisObject.themesSortOrder == "ascending") {
          themes.sort((a, b) => a.created_on - b.created_on)
        } else {
          themes.sort((a, b) => b.created_on - a.created_on)
        }
      } else if (this.textAnalysisObject.themesSortType == "alphabetical") {
        if (this.textAnalysisObject.themesSortOrder == "ascending") {
          themes.sort((a, b) => a.name.localeCompare(b.name))
        } else {
          themes.sort((a, b) => b.name.localeCompare(a.name))
        }
      } else {
        // default
        if (this.textAnalysisObject.themesSortType == "coverage") {
          if (this.textAnalysisObject.themesSortOrder == "ascending") {
            if (
              this.textAnalysisObject.filterParams &&
              Object.keys(this.textAnalysisObject.filterParams).length
            ) {
              themes.sort(
                (a, b) => a.filtered_coverage_count - b.filtered_coverage_count
              )
            } else {
              themes.sort((a, b) => a.coverage_count - b.coverage_count)
            }
          } else {
            if (
              this.textAnalysisObject.filterParams &&
              Object.keys(this.textAnalysisObject.filterParams).length
            ) {
              themes.sort(
                (a, b) => b.filtered_coverage_count - a.filtered_coverage_count
              )
            } else {
              themes.sort((a, b) => b.coverage_count - a.coverage_count)
            }
          }
        }
      }
      this.sortedThemes = themes
    },

    // ***
    // UTILITIES SECTION

    getSentimentLabel(score) {
      if (score < -0.75) {
        return "Strongly negative 😡"
      } else if (score < -0.25) {
        return "Somewhat negative 😟"
      } else if (score < 0.25) {
        return "Neutral 😐"
      } else if (score < 0.75) {
        return "Somewhat positive 🙂"
      } else {
        return "Strongly positive 😃"
      }
    },
    getSentimentEmoji(score) {
      if (score < -0.75) {
        return "😡"
      } else if (score < -0.25) {
        return "😟"
      } else if (score < 0.25) {
        return "😐"
      } else if (score < 0.75) {
        return "🙂"
      } else {
        return "😃"
      }
    },
    getSentimentStyle(score) {
      if (score < -0.75) {
        return "background-color: var(--very-negative-soft); color: white;"
      } else if (score < -0.25) {
        return "background-color: var(--somewhat-negative-soft);"
      } else if (score < 0.25) {
        return ""
      } else if (score < 0.75) {
        return "background-color: var(--somewhat-positive-soft)"
      } else {
        return "background-color: var(--very-positive-soft)"
      }
    },

    generateSentimentText(score) {
      return score >= 0
        ? `${(score * 100).toFixed(1)}% positive sentiment`
        : `${(score * -100).toFixed(1)}% negative sentiment`
    },
    getModalRef() {
      return this.$refs[this.modalRef]
    },
    showModalMessage(messageType, message) {
      this.getModalRef() && this.getModalRef().showMessage(messageType, message)
    },
    getApiLanguage() {
      return window.localStorage.getItem("apiLanguage") || ""
    },
    deepCloneObj(obj) {
      // deep clones an object using JSON stringify (data loss might occur)
      if (Array.isArray(obj)) {
        return obj.map((item) => JSON.parse(JSON.stringify(item)))
      } else if (typeof obj == "object") {
        return JSON.parse(JSON.stringify(obj))
      }
    },
    arrayEquals(array1, array2) {
      return JSON.stringify(array1.sort()) === JSON.stringify(array2.sort())
    },
    isEmpty(theme) {
      return !theme || (theme.keywords.length === 0 && theme.notes.length === 0)
    },
    _alert(
      message,
      title,
      confirmType,
      confirmSourceComponent,
      btnText = "Okay"
    ) {
      this.setConfirmText({
        btn: btnText,
        title: title,
        message: message
      })
      this.setConfirmType(confirmType)
      this.setConfirmSourceComponent(confirmSourceComponent)
      this.setConfirmIsVisible(true)
    }
  },
  computed: {
    TEXT_SERVICE() {
      return this.$services.TEXT_SERVICE
    },
    modalRef: function () {
      return `modal-${this.$.uid}`
    },
    modalOpen() {
      return this.$store.getters["getModalOpen"]
    },
    sortMode() {
      return this.textAnalysisObject.sentimentSortOrder
    },
    currentSelectedResponseIds() {
      return this.textAnalysisObject.selectedResponseIds
    },
    isSearchMode: function () {
      if (this.textAnalysisObject && this.textAnalysisObject.search) {
        return this.textAnalysisObject.search.searchString.trim() !== ""
      }
      return false
    },
    hasSearchResults: function () {
      if (this.textAnalysisObject.search) {
        if (this.textAnalysisObject.search.searchResults.length) {
          return true
        }
      }
      return false
    },
    hasSimilarSentences: function () {
      if (this.textAnalysisObject.search) {
        if (this.textAnalysisObject.search.similarSentences.length) {
          return true
        }
      }
      return false
    },
    areThemeSelected: function () {
      if (!this.textAnalysisObject) {
        return false
      }
      return !this.textAnalysisObject.selectedThemeUUIDs.length
    },
    isVisFilterActivated: function () {
      if (!this.textAnalysisObject) {
        return false
      }
      return this.textAnalysisObject.selectedResponseIds.length > 0
    },
    searchResponseIds: function () {
      if (!this.textAnalysisObject) {
        return false
      }
      return this.textAnalysisObject.search.searchResultsIds
    },
    selectedTextQuestionId: function () {
      if (!this.textAnalysisObject.selectedTextQuestion) return undefined
      let textQuestion
      if (this.textAnalysisObject.selectedTextQuestion._id) {
        if (this.textAnalysisObject.selectedTextQuestion._id.$oid) {
          textQuestion = this.textAnalysisObject.selectedTextQuestion._id.$oid
        } else {
          textQuestion = this.textAnalysisObject.selectedTextQuestion._id
        }
      }
      return textQuestion
    }
  }
}
