import { BaseService, axios } from "@pigeonline/core"
import { app } from "@/main.js"

export default class DatasetsService extends BaseService {
  /**
   * Upload a new dataset
   * @returns {(Object|Array)} dataset object
   */
  async upload(data) {
    try {
      const URL = app.config.globalProperties.$base_URL + "/data_sets"
      const response = await axios.post(`${URL}/`, data, {
        headers: {
          Authorization: this.getAuthHeader(),
          "Content-Type": "multipart/form-data"
        }
      })
      return response
    } catch (e) {
      throw new Error("datasetsService.js:upload " + e.message)
    }
  }

  /**
   * Edit a dataset
   * @returns {(Object|Array)} dataset object
   */
  async edit(id, data) {
    try {
      const URL = app.config.globalProperties.$base_URL + "/data_sets"
      const response = await axios.put(`${URL}/${id}`, data, {
        headers: {
          Authorization: this.getAuthHeader(),
          "Content-Type": "multipart/form-data"
        }
      })
      return response.data
    } catch (e) {
      throw new Error("datasetsService.js:edit " + e.message)
    }
  }

  /**
   * Update a dataset
   * @returns {(Object)} dataset object
   */
  async update(id, data) {
    try {
      const URL = app.config.globalProperties.$base_URL + "/data_sets"
      const response = await axios.patch(`${URL}/${id}`, data, {
        headers: this.getAuthHeader()
      })
      return response.data
    } catch (e) {
      throw new Error("datasetsService.js:update " + e.message)
    }
  }

  /**
   * Delete a dataset
   * @returns {String} success message
   */
  async delete(id) {
    try {
      const URL = app.config.globalProperties.$base_URL + "/data_sets"
      const response = await axios.delete(`${URL}/${id}`, {
        headers: this.getAuthHeader()
      })
      return response.data
    } catch (e) {
      throw new Error("datasetsService.js:delete " + e.message)
    }
  }

  /**
   * Get all datasets
   * @returns {(Object|Array)} dataset object or array of dataset objects
   */
  async datasets() {
    try {
      const URL = app.config.globalProperties.$base_URL + "/data_sets"
      const response = await axios.get(`${URL}/`, {
        headers: this.getAuthHeader()
      })
      return response.data
    } catch (e) {
      throw new Error("datasetsService.js:datasets " + e.message)
    }
  }

  /**
   * Get details of a particular dataset
   * @param {String} id ID of dataset
   * @returns {(Object|Array)} dataset object or array of dataset objects
   */
  async dataset(id, params = {}) {
    try {
      const URL = app.config.globalProperties.$base_URL + "/data_sets"
      let config = {}
      config["headers"] = this.getAuthHeader()
      config["params"] = params ? params : ""
      const response = await axios.get(`${URL}/${id}`, config)
      return response.data
    } catch (e) {
      throw new Error("datasetsService.js:dataset " + e.message)
    }
  }

  /**
   * Create new global question
   * @param {String} data new global question
   * @returns {Object} global_question
   */
  async newGlobalQuestion(data) {
    try {
      const URL = app.config.globalProperties.$base_URL + "/data_sets"
      const response = await axios.post(`${URL}/global_questions`, data)
      return response.data
    } catch (e) {
      throw new Error("datasetsService.js:newGlobalQuestion " + e.message)
    }
  }

  /**
   * Get global questions (or a specific question) based on params
   * @param {String} [id] of globbal question to fetch
   * @param {String} [params] paramteres to filter by
   * @returns {(Object|Array)} object or array of global_questions
   */
  async globalQuestions(id, params = {}) {
    try {
      const URL = app.config.globalProperties.$base_URL + "/data_sets"
      let config = {}
      config["headers"] = this.getAuthHeader()
      config["params"] = params ? params : ""

      let url = `${URL}/global_questions${id ? `/${id}` : ""}`

      const response = await axios.get(url, config)
      return response.data
    } catch (e) {
      throw new Error("datasetsService.js:globalQuestions " + e.message)
    }
  }

  async updateGlobalQuestion(id, data) {
    try {
      const URL = app.config.globalProperties.$base_URL + "/data_sets"
      const response = await axios.patch(
        `${URL}/global_questions/${id}`,
        data,
        {
          headers: this.getAuthHeader()
        }
      )
      return response.data
    } catch (e) {
      throw new Error("datasetsService.js:updateGlobalQuestion " + e.message)
    }
  }

  /**
   * Delete global question
   * @param {String} id of global question
   */
  async deleteGlobalQuestion(id) {
    try {
      const URL = app.config.globalProperties.$base_URL + "/data_sets"
      const response = await axios.delete(`${URL}/global_questions/${id}`)
      return response.data
    } catch (e) {
      throw new Error("datasetsService.js:newGlobalQuestion " + e.message)
    }
  }

  /**
   * Get client questions (or a specific question) based on params
   * @param {String} [id] of client question to fetch
   * @param {String} [params] paramteres to filter by
   * @returns {(Object|Array)} object or array of client_questions
   */
  async clientQuestions(id, params = {}) {
    try {
      const URL = app.config.globalProperties.$base_URL + "/data_sets"
      let config = {}
      config["headers"] = this.getAuthHeader()
      config["params"] = params ? params : ""
      let url = `${URL}/client_questions${id ? `/${id}` : ""}`

      const response = await axios.get(url, config)
      return response.data
    } catch (e) {
      throw new Error("datasetsService.js:clientQuestions " + e.message)
    }
  }

  /**
   * Get a client question object
   * @param {String} id of client question to fetch
   * @param {String} data client question data
   * @returns {(Object|Array)} client_question object
   */
  async getClientQuestion(id) {
    try {
      const URL = app.config.globalProperties.$base_URL + "/data_sets"
      const response = await axios.get(`${URL}/client_questions/${id}`)
      return response.data
    } catch (e) {
      if (e.response.status === 304) return e
      throw new Error("datasetsService.js:getClientQuestion " + e.message)
    }
  }

  /**
   * Update a client questions
   * @param {String} id of client question to fetch
   * @param {String} data client question data
   * @returns {(Object|Array)} client_question object
   */
  async updateClientQuestion(id, data) {
    try {
      const URL = app.config.globalProperties.$base_URL + "/data_sets"
      const response = await axios.patch(`${URL}/client_questions/${id}`, data)
      return response.data
    } catch (e) {
      if (e.response.status === 304) return e
      throw new Error("datasetsService.js:updateClientQuestion " + e.message)
    }
  }

  /**
   * Update a client questions
   * @param {String} id of client question to fetch
   * @param {String} data client question data
   * @returns {(Object|Array)} client_question object
   */
  async editClientQuestion(id, data) {
    try {
      const URL = app.config.globalProperties.$base_URL + "/data_sets"
      delete data._id
      const response = await axios.put(`${URL}/client_questions/${id}`, data)
      return response.data
    } catch (e) {
      throw new Error("datasetsService.js:clientQuestions " + e.message)
    }
  }

  /**
   * Tell the backend to generate lemmas
   */
  async addLemmasToResponses(request) {
    try {
      const URL = app.config.globalProperties.$base_URL + "/data_sets"
      const response = await axios.post(
        `${URL}/add_lemmas_to_responses`,
        request,
        {
          headers: this.getAuthHeader()
        }
      )
      return response.data
    } catch (e) {
      throw new Error("textService.js:addLemmasToResponses " + e.message)
    }
  }

  /**
   * Tell the backend to generate sentiments
   */
  async addSentimentsToResponses(request) {
    try {
      const URL = app.config.globalProperties.$base_URL + "/data_sets"
      const response = await axios.post(
        `${URL}/add_sentiments_to_responses`,
        request,
        {
          headers: this.getAuthHeader()
        }
      )
      return response.data
    } catch (e) {
      throw new Error("textService.js:addSentimentsToResponses " + e.message)
    }
  }

  /**
   * Tell the backend to tokenize sentences
   */
  async addSentenceTokensToResponses(request) {
    try {
      const URL = app.config.globalProperties.$base_URL + "/data_sets"
      const response = await axios.post(
        `${URL}/add_sentence_tokens_to_responses`,
        request,
        {
          headers: this.getAuthHeader()
        }
      )
      return response.data
    } catch (e) {
      throw new Error(
        "textService.js:addSentenceTokensToResponses " + e.message
      )
    }
  }

  /**
   * Collect all responses
   */
  async getClientQuestionTextResponses(request) {
    try {
      const URL = app.config.globalProperties.$base_URL + "/data_sets"
      const response = await axios.post(`${URL}/get_text_responses`, request, {
        headers: this.getAuthHeader()
      })
      return response.data
    } catch (e) {
      throw new Error(
        "textService.js:getClientQuestionTextResponses " + e.message
      )
    }
  }

  /**
   * Delete client question
   * @param {String} id of global question
   */
  async deleteClientQuestion(id) {
    try {
      const URL = app.config.globalProperties.$base_URL + "/data_sets"
      const response = await axios.delete(`${URL}/client_questions/${id}`)
      return response.data
    } catch (e) {
      throw new Error("datasetsService.js:deleteClientQuestion " + e.message)
    }
  }

  /**
   * Delete multiple client questions
   * @param {Array} ids array of client questions to delete
   * @returns {String} success message
   */
  async batchDeleteClientQuestions(ids) {
    try {
      const URL = app.config.globalProperties.$base_URL + "/data_sets"
      const response = await axios.post(
        `${URL}/client_questions/delete_requests`,
        {
          client_questions_ids: ids
        }
      )
      return response.data
    } catch (e) {
      throw new Error(
        "datasetsService.js:batchDeleteClientQuestions " + e.message
      )
    }
  }

  /**
   * Find zombie datasets
   */
  async findZombieDatasets(request) {
    try {
      const URL = app.config.globalProperties.$base_URL + "/data_sets"
      const response = await axios.post(`${URL}/find_zombie_data`, request, {
        headers: this.getAuthHeader()
      })
      return response.data
    } catch (e) {
      throw new Error("textService.js:findZombieDatasets " + e.message)
    }
  }
}
